.ctrl-btn {
  position: absolute;
  display: flex;
  gap: 8px;
  top: 20px;
  right: 20px;

  button {
    width: 36px;
    height: 36px;
  }
  &.edit{
    top: 17px;
    right: 17px;
  }
}

.ctrl-btn__zoom {
  display: inline-flex;

  :first-child {
    border-radius: 4px 0 0 4px;
  }

  :last-child {
    border-radius: 0 4px 4px 0;
  }
}