.coords-view {
  position: absolute;
  align-items: center;
  display: flex;
  bottom: 0;
  right: 0;
  width: 182px;
  height: 24px;
  background: var(--text-color-4);
  opacity: 0.72;
  border-radius: 4px 0 0 0;
  padding-left: 12px;
  gap: 10px;

  &.edit {
    bottom: -3px;
    right: -3px;
  }
}