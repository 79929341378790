.construction-conditions__container{
    //width: 442px;
    padding-right: 40px;
    border-right: 1px solid var(--border-color);

    & .grid-table {
        & :last-child {
            &:before {
                display: none;
            }
        }
    }
}

.ground-construction-conditions__container{
    padding-left: 40px;

    & .grid-table {
        & :first-child {
            &:before {
                display: none;
            }
        }
    }
}
.construction-conditions__content{
    display: flex;
}
.construction-conditions__title{
    padding-bottom: 16px;
}
