.project-card{
  display: flex;
  flex-direction: column;
  gap: 4px;
  cursor: pointer;
  width: 100%;
  max-height: 39px;
}
.project-card__top{
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
}
.project-card__flex-start{
  display: flex;
  align-items: center;
  gap: 4px;
  max-width: 263px;
  min-width: 263px;
}
.project-title{
  max-width: 239px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: var(--primary-text-color);
}
.project-owner, .project-date, .project-id{
  font-size: 12px;
  line-height: 15.06px;
  color: var(--secondary-text-color);
}
.project-owner{
  align-self: center;
  max-width: 180px;
  min-width: 180px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.project-card__flex-end{
  display: flex;
  justify-content: space-between;
  //gap: 20px;
  width: 332px;
}
.project-card__left{
  display: flex;
  align-items: center;
  gap: 20px;
}
.project-card__bottom{
  padding-left: 28px;
}
