.directory__footer {
  width: 100%;
  padding: 20px;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  background: var(--white-color);
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.16);
}

.directory__content {
  padding: 20px;
}

.directory__footer-delete-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.directory__footer-delete-text {
  color: var(--secondary-text-color);
}

.directory__footer-delete-number {
  color: var(--primary-color);
}

.main-button:disabled.directory__footer-delete-btn,
.main-button:disabled.directory__footer-add-btn {
  & .main-button__icon-wrapper {
    & svg path {
      fill: none;
      stroke: var(--secondary-text-color);
    }
  }
}

.directory-table__container {
  overflow: auto;
  max-height: calc(100vh - 64px - 81px - 20px - 20px);

  & .dropdown__value-container {
    border-radius: 0;

    &:disabled {
      border: none;
    }
  }
}

.directory-table__container-error {
  padding-top: 20px;
  padding-left: 60px;
}

.pipe-type-table__container, .gpu-table__container, .nextgis-layers-table__container {
  min-height: 234px;
  border: none;

  .grid-table {
    border: 1px solid var(--border-color);
  }
}
