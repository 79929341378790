.unit-costs-table__container{
  max-height: calc(100vh - 64px - 20px - 20px);
}
.unit-cost-table__header{
  background: var(--grey-color) !important;
  color: var(--primary-text-color) !important;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
}
