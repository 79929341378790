
* {
  font-family: 'Mulish', sans-serif;
  box-sizing: border-box;
}

*,*:focus,*:hover{
    outline:none;
}
div {
  scrollbar-width: thin;
}

body {
    margin: 0;
    /*overflow: hidden;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button {
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
}

::-webkit-scrollbar {
    background: #F5F5F5;
    width: 16px;
    height: 16px;
}

::-webkit-scrollbar-thumb {
    background-color: #DADFE5;
    background-clip: content-box;
    border: 2px solid transparent;
    border-radius: 4px;
    max-width: 30px;
}

::-webkit-scrollbar-track-piece {
    background-color: transparent;
    -webkit-border-radius: 6px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
hr {
    border: none;
    height: 1px;
    background-color: var(--border-color);
    color: var(--border-color);
    margin: 0;
}

.square-btn-28 {
  height: 28px;
  width: 28px;
}
.square-btn-36{
  height: 36px;
  width: 36px;
}

.superscript-wrapper {
  position: relative;
}

.superscript {
  position: absolute;
  top: -4px;
  right: -4px;
  font-size: 8px;
}

.superscript-rel {
  position: relative;
  top: -0.5em;
  font-size: 80%;
}
