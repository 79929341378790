.message-box{
  display: inline-flex;
  align-items: center;
  color: var(--text-color-5);
  gap: 8px;
  width: 100%;
  padding: 12px;
  background-color: var(--background-color-4);
  border-radius: 4px;
  margin-bottom: 28px;
  .error-message{
    width: calc(100% - 40px - 17px - 8px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.showAll{
      overflow: visible;
      white-space: normal;
    }
  }
  .show-all__btn{
    color: var(--blue-color);
    cursor: pointer;
    white-space: nowrap;
    text-decoration: underline;
  }
  svg path {
    stroke: var(--text-color-5);
    fill: var(--text-color-5);
  }
}