.mode-control{
  position: absolute;
  display: inline-flex;
  top: 20px;
  left: 20px;
  gap: 30px;
  &.edit{
    top: 17px;
  left: 17px;
  }
  &>button:first-child svg{
    margin: 0 2px;
  }
  #edit_icn path {
    fill: transparent;
    stroke: white;
  }
  & > button{
    border-radius: 4px;
  }
  //:first-child {
  //  border-radius: 4px 0 0 4px;
  //}
  //
  //:last-child {
  //  border-radius: 0 4px 4px 0;
  //}
}
#mode-control__btn{
  &.selected{
    background-color: var(--background-color-5);
  }
  &.selected:hover{
    background-color: var(--background-color-5);
  }
  &.selected:active{
    background-color: var(--background-color-5);
  }
}