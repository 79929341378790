.hydraulic-calc__container {
  max-height: calc(100vh - 64px);
  width: 860px;
  min-width: min(860px,calc(100% - 156px - 52px));
  max-width: calc(100% - 156px - 52px);
  background-color: var(--white-color);
  z-index: 10;
  height: calc(100vh - 64px);
  display: grid;
}

.hydraulic-calc__content {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 64px - 76px);
  padding: 28px 20px;
  height: fit-content;
}

.hydraulic-calc__title {
  color: var(--primary-text-color);
}

.hydraulic-calc__sub-title {
  color: var(--primary-text-color);
  margin-bottom: 20px;
}

.hydraulic-calc__scheme-btn {
  padding: 8px 12px 8px 8px;
}

.hydraulic-calc__status-wrapper {
  display: flex;
  align-items: center;
}

.hydraulic-calc__top-row {
  margin-bottom: 28px;
  display: flex;
  justify-content: space-between;
}

.hydraulic-calc__left {
  display: flex;
  gap: 20px;
}

.calc__bottom-panel {
  align-self: flex-end;
  padding: 20px;
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  gap: 16px;
  z-index: 30;

  & .main-button.primary .main-button__icon-wrapper .redo-icn path {
    stroke: white;
    fill: none;
  }
}
