.map {
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 64px;
  width: calc(100% - 372px);
  height: calc(100vh - 64px);
  flex: 1 1 0;
  background: var(--background-color-1);

  &.edit.add_point {
    cursor: url(../../images/cursor/cursor.cur), auto;
  }

  &.edit {
    border: 3px solid var(--background-color-5);

    .maplibregl-canvas {
      top: -3px;
      left: -3px;
    }

    .maplibregl-ctrl-scale {
      bottom: -1px;
      right: 199px;
    }
  }

  &.creation {
    cursor: crosshair;
  }
}

.maplibregl-ctrl-scale {
  position: absolute;
  padding-left: 2px;
  height: 13px;
  bottom: 2px;
  right: 202px;
  background: var(--white-color);
  border: 1px solid black;
  border-top: none;
  font-size: 10px;
}

.maplibregl-canvas {
  position: absolute;
}

.maplibregl-ctrl-attrib-button {
  display: none;
}