.trunk-pipeline__panel {
  position: unset;
  max-width: 100%;

  .edit-element-panel__table {
    grid-template-columns: repeat(3, minmax(auto, 182px));
  }

  .table-container {
    max-height: calc(100vh - 131px - 64px);
  }

  .grid-table {
    min-width: 1381px;
    width: 100%;
  }
}

.pipes-table {
  display: flex;
  min-width: 744px;
  max-width: 1272px;
  flex-direction: column;
}

.pipes-table__header {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;

  button {
    width: 28px;
    height: 28px;
  }
}

.pipes-table__body {
  display: flex;
  flex-direction: column;
  gap: 10px;

  hr:last-child {
    display: none;
  }
}

.pipe-params__body-row {
  display: grid;
  grid-template-columns: 20px minmax(90px, 182px) repeat(4, minmax(106px, 182px)) minmax(97px, 182px) minmax(39px, 182px);
  column-gap: 10px;
}

.pipes-number {
  padding-right: 10px;
  border-right: 1px solid var(--border-color);
}

.pipe-params__type-btn {
  display: flex;
  align-items: flex-end;

  button {
    max-height: 36px;
  }
}

.pipe-params__delete-btn {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 8px;
}

.trunk-pipeline__resizable-container {
  max-height: calc(100vh - 64px);
  width: 816px;
  min-width: min(816px, calc(100% - 156px - 320px - 52px));
  max-width: calc(100% - 156px - 320px - 52px);
  background-color: var(--white-color);
  z-index: 10;
}

.trunk-pipeline-table__container {
  & .grid-table__header:after {
    display: block;
    content: '';
    position: absolute;
    width: 1px;
    height: calc(100% + 1px);
    top: 0;
    right: -1px;
    background-color: var(--border-color);
  }
}

