.resizable-container {
  position: relative;
}

.resizable-container__handle {
  position: absolute;
  height: 100%;
  top: 0;
  right: -4px;
  width: 8px;

  &:hover {
    cursor: ew-resize;
  }
}
