.checkbox-row {
  &.start-aligned {
    display: flex;
    gap: 8px;
    align-items: flex-start;
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &:disabled {
    .checkbox-icon {
      border-color: var(--secondary-text-color);
    }

    .checked-icon path {
      fill: var(--secondary-text-color);
    }
  }
}

.checkbox-label {

}

.checkbox-icon {
  position: relative;
  width: 18px;
  height: 18px;
  background: var(--white-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;

  & .checked-icon {
    position: absolute;
    top: 50%;
    left: 54%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.checkbox-content {
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: var(--secondary-text-color);
}
