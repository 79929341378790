.main-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: default;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;

  &.with-icon {
    padding: 10.5px 12px 10.5px 8px;
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &.primary {
    color: var(--white-color);
    background: var(--primary-color);

    &:not(:disabled):hover {
      background: var(--primary-hover-color);
    }

    &:not(:disabled):active,
    &:not(:disabled).active {
      background: var(--primary-active-color);
    }

    &:disabled {
      background: var(--disabled-color);
      color: var(--secondary-text-color);

      & .main-button__icon-wrapper {
        & svg path {
          fill: var(--secondary-text-color);
        }
      }
    }

    & .main-button__icon-wrapper {
      & svg path {
        fill: var(--white-color);
      }
    }
  }

  &.primary-light {
    color: var(--white-color);
    background: var(--primary-light-color);

    &:not(:disabled):hover {
      background: var(--primary-light-hover-color);
    }

    &:not(:disabled):active,
    &:not(:disabled).active {
      background: var(--primary-light-active-color);
    }

    //&:disabled {
    //  background: #E2EBF3;
    //  color: #373737;
    //
    //  & .main-button__icon-wrapper {
    //    & svg path {
    //      fill: #373737;
    //      stroke: #373737;
    //    }
    //  }
    //}

    & .main-button__icon-wrapper {
      & svg path {
        fill: var(--white-color);
        stroke: var(--white-color);
      }
    }
  }

  &.secondary {
    color: var(--primary-text-color);
    background: var(--secondary-color);

    &:not(:disabled):hover {
      background: var(--secondary-hover-color);
    }

    &:not(:disabled):active {
      background: var(--secondary-active-color);
    }

    &.selected {
      border: 2px solid var(--primary-color);

      & .main-button__icon-wrapper {
        & svg path {
          stroke: var(--primary-color);
          fill: var(--primary-color);
        }
      }
    }

    &:disabled {
      background: var(--disabled-color);
      color: var(--secondary-text-color);

      & .main-button__icon-wrapper {
        & svg path {
          //fill: var(--secondary-text-color);
          stroke: var(--secondary-text-color);
        }
      }
    }

    & .main-button__icon-wrapper {
      & svg path {
        stroke: var(--primary-text-color);
      }
    }
  }

  &.drop-down {
    background-color: var(--white-color);
    justify-content: flex-start;
    display: flex;
    gap: 8px;
    width: 100%;
    color: var(--primary-text-color);
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;

    &:hover {
      background: var(--grey-hover-color);
    }
  }

  &.warn {
    background-color: var(--alert-color);
    color: var(--white-color);

    &:not(:disabled):hover {
      background: var(--alert-hover-color);
    }

    &:not(:disabled):active {
      background: var(--alert-active-color);
    }

    &:disabled {
      background: var(--disabled-color);
      color: var(--secondary-text-color);
    }
  }

  &.info {
    background-color: transparent;
    padding: 0;

    &:not(:disabled):hover {
      background: var(--grey-hover-color);
    }

    &:not(:disabled):active {
      background: var(--grey-active-color);
    }
  }
}

.main-button__icon-wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}

.input-wrapper.error,
.input-wrapper.warning {
  & .main-button.info {
    &:hover {
      background: transparent;
    }

    &:active {
      background: transparent;
    }
  }
}

