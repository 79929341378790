.resize-handle {
  position: absolute;
  right: -4px;
  top: 50%;
  background: #FFFFFF;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 10px 4px;
  cursor: pointer;
  font-size: 0;

  &:hover {
    cursor: ew-resize;
    background: var(--white-hover-color);
  }
}

.dragging {
  & .resize-handle {
    background: var(--white-active-color);
  }
}
