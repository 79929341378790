.layer-control {
  position: absolute;
  top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
  right: calc(60px + 116px + 20px);
  &.edit{
    top: 17px;
    right: calc(60px + 116px + 20px - 3px);
  }
}

.layer-control__btn {
  height: 36px;
  width: 84px;

  svg {
    margin-left: 5px;
  }

  svg path {
    stroke: var(--white-color);
    fill: var(--white-color);
  }

  &.open svg {
    transform: rotate(-180deg);
  }
}

.layer-control__dropdown-container {
  display: flex;
  flex-direction: column-reverse;
  gap: 1px;
  background: var(--background-color-2);
  border-radius: 4px;
  width: 147px;
  color: var(--white-color);

  &:not(.open) {
    display: none;
  }
}

.layer-control__dropdown-btn {
  display: flex;
  justify-content: flex-start;
  padding-left: 11px;
  gap: 7px;
  align-items: center;
  height: 36px;
  background: var(--primary-color);
  border-radius: 4px;
  line-height: 15.06px;
  font-weight: 700;
  font-size: 12px;
}